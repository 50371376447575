/**
 * author: Alex Lai
 * Support Website: https://laialex.com
 * Email: alex@laialex.com
 * Create Time: 2024/10/8
 */
import React, { Component } from 'react'
import * as ui from 'antd'
import apis from '../../../../utils/apis'
import { BellOutlined } from '@ant-design/icons'
import { PageHeader } from '@ant-design/pro-layout'


const { TextArea } = ui.Input
const { Option } = ui.Select

export default class CrawlerEngine extends Component {
  constructor() {
    super()
    this.state = {
      tableData: [],
      paging: { total: 0, pageNumber: 1, pageSize: 20 },
      tableLoad: false,
      searchKey: {},
      editShow: false,
      editCurr: {},
      submitting: false,
      executeGroup: [],
      gatherType: []
    }
  }

  componentDidMount() {
    this.gatherTypeSync()
    this.tableDataSync(1)
  }


  /**
   * 采集类型加载
   */
  gatherTypeSync() {
    var that = this
    apis.crawlerTypeList({}).then(res => {
      that.setState({
        gatherType: res.data.data
      })
    })
  }

  /**
   * 发起搜索
   * @param params
   * @constructor
   */
  makeSearch = (params) => {
    this.setState({
      searchKey: params
    })
    var that = this
    setTimeout(function() {
      that.tableDataSync()
    }, 200)
  }
  /**
   * 签名列表
   * @param pageNumber
   Object.assign(para, that.state.keywords)
   * @param pageSize
   * @constructor
   */
  tableDataSync = (pageNumber = 1) => {
    let that = this
    let para = {}
    para.pageNumber = pageNumber
    para.pageSize = 20
    that.setState({
      tableLoad: true
    })
    Object.assign(para, that.state.searchKey)
    apis.crawlerSourceList(para).then(function(resp) {
      that.setState({
        tableLoad: false
      })
      var curr = {
        total: resp.data.data.total,
        pageNumber: pageNumber
      }
      that.setState({
        tableData: resp.data.data.data,
        paging: curr
      })
    })
  }


  render() {
    var that = this

    const columns = [
      {
        title: 'ID',
        dataIndex: 'id'
      },
      {
        title: '源名称',
        key: 'id',
        render: function(value, row, index) {
          return (
            <div style={{ fontSize: '22px'}}>{row.name}</div>
          )
        }
      },
      {
        title: '源域名',
        key: 'id',
        render: function(value, row, index) {
          return (
            <div style={{ fontSize: '22px'}}>{row.domain}</div>
          )
        }
      },
      {
        title: '采集类型',
        key: 'id',
        render: function(value, row, index) {
          return (
            <div>{row.gather_type.name}</div>
          )
        }
      },
      {
        title: '采集器',
        key: 'id',
        render: function(value, row, index) {
          return (
            <ui.Button shape={'round'} type={'dashed'} size={"small"}>{row.spider}</ui.Button>
          )
        }
      },
      {
        title: '可用',
        key: 'id',
        render: function(value, row, index) {
          return (
            <ui.Button shape={'round'} type={'circle'}>{row.state ? '是' : '否'}</ui.Button>
          )
        }
      },
      {
        title: '管理',
        key: 'id',
        fixed: 'right',
        render: function(value, row, index) {
          return (
            <span>
                 <ui.Button onClick={() => {
                   var data = JSON.parse(JSON.stringify(row))
                   data.gather_type = data.gather_type.id
                   that.setState({
                     editCurr: data,
                     editShow: true
                   })
                 }} type={'link'}>编辑</ui.Button>
            </span>
          )
        }
      }
    ]

    return (
      <div>
        <PageHeader title="采集源管理" subTitle="统一管理采集数据源" extra={[
          <ui.Button key="1" type="primary" shape="round" onClick={() => {
            that.setState({
              editCurr: {},
              editShow: true,
              submitting: false
            })
          }}>新增采集源</ui.Button>
        ]} />
        <ui.Card>
          <div style={{ marginTop: '22px' }}>
            <ui.Form onFinish={that.makeSearch} layout={'inline'}>
              <ui.Form.Item label={'采集源名称'} name={'name__contains'}>
                <ui.Input placeholder={'采集源名称'} />
              </ui.Form.Item>
              <ui.Form.Item>
                <ui.Button type={'primary'} htmlType={'submit'}>快速搜索</ui.Button>
              </ui.Form.Item>
            </ui.Form>
          </div>
        </ui.Card>

        <div style={{ marginTop: '22px' }}>
          <ui.Table
            rowKey="id"
            dataSource={this.state.tableData}
            columns={columns}
            loading={this.state.tableLoad}
            pagination={{
              total: this.state.paging.total,
              defaultPageSize: 20,
              defaultCurrent: this.state.paging.pageNumber,
              onChange: this.tableDataSync
            }} />
        </div>

        <ui.Modal open={that.state.editShow} title={'定时任务采集源名称'} onCancel={() => {
          that.setState({
            editCurr: {},
            editShow: false,
            submitting: false
          })
        }} footer={null} destroyOnClose>
          <ui.Form onFinish={(params => {
            that.setState({
              submitting: true
            })
            if (that.state.editCurr.id) {
              params.id = that.state.editCurr.id
            }
            apis.crawlerSourceModify(params).then(resp => {
              that.setState({
                submitting: false
              })
              if (resp.data.code === 200) {
                ui.message.success('提交信息成功')
                that.tableDataSync(that.state.editCurr.id ? that.state.paging.pageNumber : 1)
                that.setState({
                  submitting: false,
                  editShow: false,
                  editCurr: {}
                })
              } else {
                ui.message.error(resp.data.data.msg ? resp.data.data.msg : resp.data.msg)
              }
            })
          })} labelCol={{ span: 24 }} labelAlign={'left'} initialValues={that.state.editCurr}>
            <ui.Form.Item label={'采集源名称'} name={'name'}
                          rules={[{ required: true, message: '采集源名称' }]}>
              <ui.Input placeholder={'采集源名称'} />
            </ui.Form.Item>
            <ui.Form.Item label={'采集类型'} name={'gather_type'} rules={[{ required: true, message: '采集类型' }]}>
              <ui.Select placeholder={'选择使用的采集类型'} style={{ width: '100%' }}>
                {that.state.gatherType.map(item => {
                  return (
                    <Option value={item.id} key={item.id}>{item.name}</Option>
                  )
                })}
              </ui.Select>
            </ui.Form.Item>
            <ui.Form.Item label={'采集源状态'} name={'state'}
                          rules={[{ required: true, message: '采集源状态' }]}>
              <ui.Radio.Group>
                <ui.Radio value={true}>可用</ui.Radio>
                <ui.Radio value={false}>禁用</ui.Radio>
              </ui.Radio.Group>
            </ui.Form.Item>
            <ui.Form.Item label={'访问器'} name={'spider'}
                          rules={[{ required: true, message: '"Merchant", this.GetSignUser().Merchant.Id' }]}>
              <ui.Radio.Group>
                <ui.Radio value={"httpGet"}>http请求</ui.Radio>
                <ui.Radio value={"browser"}>浏览器访问</ui.Radio>
              </ui.Radio.Group>
            </ui.Form.Item>
            <ui.Form.Item label={'域名'} name={'domain'} help="地址样式:baidu.com"
                          rules={[{ required: true, message: '域名' }]}>
              <ui.Input placeholder={'域名'} />
            </ui.Form.Item>
            <ui.Form.Item label={'基础URL'} name={'base_url'} help="地址样式:https://www.baidu.com"
                          rules={[{ required: true, message: '基础URL' }]}>
              <ui.Input placeholder={'基础URL'} />
            </ui.Form.Item>
            <fieldset>
              <legend style={{ fontSize: '12px', color: '#ccc' }}>以下为列表采集规则，未配置则不自动采集列表</legend>
              <ui.Form.Item label={'下级目录拾取器'} name={'sub_directory'}
                            rules={[{ required: false, message: '下级目录拾取器' }]}>
                <TextArea rows={6} placeholder={'下级目录拾取器'} />
              </ui.Form.Item>
              <ui.Form.Item label={'详情页拾取器'} name={'page_rule'}
                            rules={[{ required: false, message: '详情页拾取器' }]}>
                <TextArea rows={6} placeholder={'详情页拾取器'} />
              </ui.Form.Item>
              <ui.Form.Item label={'页面入口地址'} name={'gate_url'}
                            rules={[{ required: false, message: '页面入口地址' }]}>
                <TextArea rows={6} placeholder={'页面入口地址'} />
              </ui.Form.Item>
              <ui.Form.Item label={'入口节点拾取器'} name={'gate_node'}
                            rules={[{ required: false, message: '入口节点拾取器' }]}>
                <TextArea rows={6} placeholder={'入口节点拾取器'} />
              </ui.Form.Item>
              <ui.Form.Item label={'入口节点取值范围'} name={'gate_range'}
                            rules={[{ required: false, message: '入口节点取值范围' }]} help={'如：1,6'}>
                <TextArea rows={6} placeholder={'入口节点取值范围'} />
              </ui.Form.Item>
              <ui.Form.Item label={'入口节点目标拾取器'} name={'gate_target'}
                            rules={[{ required: false, message: '入口节点目标拾取器' }]} help={'如：1,6'}>
                <TextArea rows={6} placeholder={'入口节点目标拾取器'} />
              </ui.Form.Item>
              <ui.Form.Item label={'入口连接节点拾取器'} name={'link_node'}
                            rules={[{ required: false, message: '入口连接节点拾取器' }]}>
                <TextArea rows={6} placeholder={'入口连接节点拾取器'} />
              </ui.Form.Item>
              <ui.Form.Item label={'入口连接拾取器'} name={'link_target'}
                            rules={[{ required: false, message: '入口连接拾取器' }]}>
                <TextArea rows={6} placeholder={'入口连接目标拾取器'} />
              </ui.Form.Item>
              <ui.Form.Item label={'入口连接名称拾取器'} name={'link_name'}
                            rules={[{ required: false, message: '入口连接名称拾取器' }]}>
                <TextArea rows={6} placeholder={'入口连接名称拾取器'} />
              </ui.Form.Item>
              <ui.Form.Item label={'休停规则'} name={'sleep_range'}
                            rules={[{ required: false, message: '休停规则' }]} help={'如:2,6休停2到6秒'}>
                <ui.Input placeholder={'休停规则'} />
              </ui.Form.Item>
              <ui.Form.Item label={'最大页数'} name={'max_page_size'}
                            rules={[{ required: false, message: '最大页数' }]} help={'如:2,6休停2到6秒'}>
                <ui.InputNumber style={{width:"100%"}} placeholder={'最大采集页数'} />
              </ui.Form.Item>
            </fieldset>
            <ui.Form.Item>
              <ui.Button htmlType={'submit'} loading={that.state.submitting} type={'primary'} block>立即提交</ui.Button>
            </ui.Form.Item>
          </ui.Form>
        </ui.Modal>
      </div>
    )
  }
}