/**
 * author: Alex Lai
 * Support Website: https://laialex.com
 * Email: alex@laialex.com
 * Create Time: 2024/10/9
 */
import React, { Component } from 'react'
import * as ui from 'antd'
import apis from '../../../../utils/apis'
import { BellOutlined } from '@ant-design/icons'
import { PageHeader } from '@ant-design/pro-layout'
import dayjs from 'dayjs'
import queryString from 'query-string'


const { TextArea } = ui.Input
const { Option } = ui.Select

export default class CrawlerSnapshot extends Component {
  constructor() {
    super()
    this.state = {
      tableData: [],
      paging: { total: 0, pageNumber: 1, pageSize: 20 },
      tableLoad: false,
      searchKey: {},
      editShow: false,
      editCurr: {},
      submitting: false
    }
  }

  componentDidMount() {

    this.tableDataSync(1)
  }


  /**
   * 发起搜索
   * @param params
   * @constructor
   */
  makeSearch = (params) => {
    this.setState({
      searchKey: params
    })
    var that = this
    setTimeout(function() {
      that.tableDataSync()
    }, 200)
  }
  /**
   * 签名列表
   * @param pageNumber
   Object.assign(para, that.state.keywords)
   * @param pageSize
   * @constructor
   */
  tableDataSync = (pageNumber = 1) => {
    let that = this
    let para = {}
    para.pageNumber = pageNumber
    para.pageSize = 20
    that.setState({
      tableLoad: true
    })
    Object.assign(para, that.state.searchKey)
    Object.assign(para, queryString.parse(window.location.search))
    apis.crawlerSnapshotList(para).then(function(resp) {
      that.setState({
        tableLoad: false
      })
      var curr = {
        total: resp.data.data.total,
        pageNumber: pageNumber
      }
      that.setState({
        tableData: resp.data.data.data,
        paging: curr
      })
    })
  }


  render() {
    var that = this

    const columns = [
      {
        title: 'ID',
        dataIndex: 'id'
      },
      {
        title: '数据源',
        key: 'id',
        render: function(value, row, index) {
          return (
            <div>{row.url.engine.domain}</div>
          )
        }
      },
      {
        title: '采集对像',
        key: 'id',
        render: function(value, row, index) {
          return (
            <div>
              <h2>{row.url.name}</h2>
            </div>
          )
        }
      },
      {
        title: '快照时间',
        key: 'id',
        render: function(value, row, index) {
          return (
            <div>{dayjs(row.created_at).format('YYYY-MM-DD hh:mm:ss')}</div>
          )
        }
      },
      {
        title: '管理',
        key: 'id',
        render: function(value, row, index) {
          return (
            <div>
              <ui.Button onClick={() => {
                var data = JSON.parse(JSON.stringify(row))
                data.data = JSON.parse(row.data)
                that.setState({
                  editCurr: data,
                  editShow: true
                })
              }} type={'link'}>数据查看
              </ui.Button>
              <ui.Button href={row.url.url} target={'_blank'} type={'link'}>立即访问</ui.Button>
            </div>
          )
        }
      }
    ]

    return (
      <div>
        <PageHeader title="数据快照" subTitle="采集数据节点快照" />
        <ui.Card>
          <div style={{ marginTop: '22px' }}>
            <ui.Form onFinish={that.makeSearch} layout={'inline'}>
              <ui.Form.Item label={'执行客户端名称'} name={'name__contains'}>
                <ui.Input placeholder={'执行客户端名称'} />
              </ui.Form.Item>
              <ui.Form.Item>
                <ui.Button type={'primary'} htmlType={'submit'}>快速搜索</ui.Button>
              </ui.Form.Item>
            </ui.Form>
          </div>
        </ui.Card>

        <div style={{ marginTop: '22px' }}>
          <ui.Table
            rowKey="id"
            dataSource={this.state.tableData}
            columns={columns}
            loading={this.state.tableLoad}
            pagination={{
              total: this.state.paging.total,
              defaultPageSize: 20,
              defaultCurrent: this.state.paging.pageNumber,
              onChange: this.tableDataSync
            }} />
        </div>

        <ui.Modal width={"760px"} open={that.state.editShow} title={'采集数据查看'} onCancel={() => {
          that.setState({
            editCurr: {},
            editShow: false,
            submitting: false
          })
        }} footer={null} destroyOnClose>
         <pre style={{maxHeight:"80vh",overflowY:"auto",padding:"16px",backgroundColor: "rgba(0,0,0,0.07)"}}>
           {JSON.stringify(that.state.editCurr.data,null,2)}
         </pre>
        </ui.Modal>
      </div>
    )
  }
}