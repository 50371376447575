/**
 * author: Alex Lai
 * Support Website: https://laialex.com
 * Email: alex@laialex.com
 * Create Time: 2024/10/8
 */
import React, { Component } from 'react'
import * as ui from 'antd'
import apis from '../../../../utils/apis'
import { BellOutlined } from '@ant-design/icons'
import { PageHeader } from '@ant-design/pro-layout'
import dayjs from 'dayjs'


const { TextArea } = ui.Input
const { Option } = ui.Select

export default class CrawlerSubscribe extends Component {
  constructor() {
    super()
    this.state = {
      tableData: [],
      paging: { total: 0, pageNumber: 1, pageSize: 20 },
      tableLoad: false,
      searchKey: {},
      editShow: false,
      editCurr: {},
      submitting: false,
      subscribeGroups: []
    }
  }

  componentDidMount() {
    this.subscribeGroupSync()
    this.tableDataSync(1)
  }

  /**
   * 加载分组
   */
  subscribeGroupSync(name) {
    var param = {}
    param.pageNumber = 1
    param.pageSize = 2000
    if (name) {
      param.name__icontains = name
    }
    var that = this
    apis.crawlerSenseList(param).then(res => {
      that.setState({
        subscribeGroups: res.data.data.data
      })
    })
  }

  /**
   * 发起搜索
   * @param params
   * @constructor
   */
  makeSearch = (params) => {
    this.setState({
      searchKey: params
    })
    var that = this
    setTimeout(function() {
      that.tableDataSync()
    }, 200)
  }
  /**
   * 签名列表
   * @param pageNumber
   Object.assign(para, that.state.keywords)
   * @param pageSize
   * @constructor
   */
  tableDataSync = (pageNumber = 1) => {
    let that = this
    let para = {}
    para.pageNumber = pageNumber
    para.pageSize = 20
    that.setState({
      tableLoad: true
    })
    Object.assign(para, that.state.searchKey)
    apis.crawlerSubscribeList(para).then(function(resp) {
      that.setState({
        tableLoad: false
      })
      var curr = {
        total: resp.data.data.total,
        pageNumber: pageNumber
      }
      that.setState({
        tableData: resp.data.data.data,
        paging: curr
      })
    })
  }


  render() {
    var that = this

    const columns = [
      {
        title: 'ID',
        dataIndex: 'id'
      },
      {
        title: '订阅场景',
        key: 'id',
        render: function(value, row, index) {
          return (
            <ui.Button size={'small'} type={'primary'}>{row.sense.name}</ui.Button>
          )
        }
      },
      {
        title: 'URL名称',
        key: 'id',
        render: function(value, row, index) {
          return (
            <div>{row.asset_url.name}</div>
          )
        }
      },
      {
        title: '订阅地址',
        key: 'id',
        render: function(value, row, index) {
          return (
            <ui.Button shape={'round'} type={'dashed'}>{row.asset_url.url}</ui.Button>
          )
        }
      },
      {
        title: '创建时间',
        key: 'id',
        render: function(value, row, index) {
          return (
            <div>{dayjs(row.created_at).format("YYYY-MM-DD hh:mm:ss")}</div>
          )
        }
      },
      {
        title: '可用',
        key: 'id',
        render: function(value, row, index) {
          return (
            <ui.Button shape={'circle'} size={'small'} type={'primary'}>{row.state ? '是' : '否'}</ui.Button>
          )
        }
      },
      {
        title: '管理',
        key: 'id',
        fixed: 'right',
        render: function(value, row, index) {
          return (
            <span>
                 <ui.Button onClick={() => {
                   var data = JSON.parse(JSON.stringify(row))
                   data.group = data.group.id
                   that.setState({
                     editCurr: data,
                     editShow: true
                   })
                 }} type={'link'}>编辑</ui.Button>
            </span>
          )
        }
      }
    ]

    return (
      <div>
        <PageHeader title="用户订阅管理" subTitle="用户订阅管理与维护" extra={[
          <ui.Button key="1" type="primary" shape="round" onClick={() => {
            that.setState({
              editCurr: {},
              editShow: true,
              submitting: false
            })
          }}>新增订阅</ui.Button>
        ]} />
        <ui.Card>
          <div style={{ marginTop: '22px' }}>
            <ui.Form onFinish={that.makeSearch} layout={'inline'}>
              <ui.Form.Item label={'用户订阅名称'} name={'name__contains'}>
                <ui.Input placeholder={'用户订阅名称'} />
              </ui.Form.Item>
              <ui.Form.Item>
                <ui.Button type={'primary'} htmlType={'submit'}>快速搜索</ui.Button>
              </ui.Form.Item>
            </ui.Form>
          </div>
        </ui.Card>

        <div style={{ marginTop: '22px' }}>
          <ui.Table
            rowKey="id"
            dataSource={this.state.tableData}
            columns={columns}
            loading={this.state.tableLoad}
            pagination={{
              total: this.state.paging.total,
              defaultPageSize: 20,
              defaultCurrent: this.state.paging.pageNumber,
              onChange: this.tableDataSync
            }} />
        </div>

        <ui.Modal open={that.state.editShow} title={'采集任务用户订阅名称'} onCancel={() => {
          that.setState({
            editCurr: {},
            editShow: false,
            submitting: false
          })
        }} footer={null} destroyOnClose>
          <ui.Form onFinish={(params => {
            that.setState({
              submitting: true
            })
            if (that.state.editCurr.id) {
              params.id = that.state.editCurr.id
            }
            apis.crawlerSubscribeSubmit(params).then(resp => {
              that.setState({
                submitting: false
              })
              if (resp.data.code === 200) {
                ui.message.success('提交信息成功')
                that.tableDataSync(that.state.editCurr.id ? that.state.paging.pageNumber : 1)
                that.setState({
                  submitting: false,
                  editShow: false,
                  editCurr: {}
                })
              } else {
                ui.message.error(resp.data.msg)
              }
            })
          })} labelCol={{ span: 24 }} labelAlign={'left'} initialValues={that.state.editCurr}>
            <ui.Form.Item label={'状态'} name={'state'}
                          rules={[{ required: true, message: '状态' }]}>
              <ui.Radio.Group>
                <ui.Radio value={true}>可用</ui.Radio>
                <ui.Radio value={false}>禁用</ui.Radio>
              </ui.Radio.Group>
            </ui.Form.Item>
            <ui.Form.Item label={'所属场景'} name={'sense'} rules={[{ required: true, message: '所属场景' }]}>
              <ui.Select showSearch={true} onSearch={kw => {
                that.subscribeGroupSync(kw)
              }} placeholder={'所属场景'} style={{ width: '100%' }}>
                {that.state.subscribeGroups.map(item => {
                  return (
                    <Option value={item.id} key={item.id}>{item.name}</Option>
                  )
                })}
              </ui.Select>
            </ui.Form.Item>

            {!that.state.editCurr.id &&
              <ui.Form.Item label={'订阅地址'} name={'subscribe_urls'} help="一行一个"
                            rules={[{ required: true, message: '订阅地址,一行一个' }]}>
                <TextArea rows={10} placeholder={'订阅地址'} />
              </ui.Form.Item>
            }

            <ui.Form.Item>
              <ui.Button htmlType={'submit'} loading={that.state.submitting} type={'primary'} block>立即提交</ui.Button>
            </ui.Form.Item>
          </ui.Form>
        </ui.Modal>
      </div>
    )
  }
}