/**
 * author: Alex Lai
 * Support Website: https://laialex.com
 * Email: alex@laialex.com
 * Create Time: 2024/10/12
 */
import React, { Component } from 'react'
import * as ui from 'antd'
import apis from '../../../../utils/apis'
import { BellOutlined } from '@ant-design/icons'
import { PageHeader } from '@ant-design/pro-layout'
import { LoadingEnded, LoadingStart } from '../../../../utils/loading'
import Button from 'antd/es/button'
import FormatUtils from '../../../../utils/FormatUtils'
import dayjs from 'dayjs'


const { TextArea } = ui.Input
const { Option } = ui.Select

export default class CrawlerModelMapping extends Component {
  constructor() {
    super()
    this.state = {
      tableData: [],
      paging: { total: 0, pageNumber: 1, pageSize: 20 },
      tableLoad: false,
      searchKey: {},
      editShow: false,
      editCurr: {},
      submitting: false,
      engine: [],
      model: [],
      modelFields: [],
      engineFields: []
    }
  }

  componentDidMount() {
    this.modelSync()
    this.sourceSync()
    this.tableDataSync(1)
  }

  /**
   * 加载执行分组
   */
  sourceSync(name) {
    var param = {}
    param.pageNumber = 1
    param.pageSize = 2000
    if (name) {
      param.name__icontains = name
    }
    var that = this
    LoadingStart()
    apis.crawlerSourceList(param).then(res => {
      LoadingEnded()
      that.setState({
        engine: res.data.data.data
      })
    })
  }


  /**
   * 模型字段
   * @param modelId
   */
  modelFieldsSync = (modelId) => {
    let that = this
    LoadingStart()
    apis.crawlerModelFieldList({
      model__id: modelId,
      pageSize: 10000
    }).then(resp => {
      LoadingEnded()
      that.setState({
        modelFields: resp.data.data.data
      })
    })

  }

  engineFieldsSync = (sourceId) => {
    let that = this
    LoadingStart()
    apis.crawlerFieldList({
      engine__id: sourceId,
      pageSize: 10000
    }).then(resp => {
      LoadingEnded()
      that.setState({
        engineFields: resp.data.data.data
      })
    })

  }


  /**
   * 模型加载
   */
  modelSync(name) {
    var param = {}
    param.pageNumber = 1
    param.pageSize = 2000
    if (name) {
      param.name__icontains = name
    }
    var that = this
    LoadingStart()
    apis.crawlerModelList(param).then(res => {
      LoadingEnded()
      that.setState({
        model: res.data.data.data
      })
    })
  }

  /**
   * 发起搜索
   * @param params
   * @constructor
   */
  makeSearch = (params) => {
    this.setState({
      searchKey: params
    })
    var that = this
    setTimeout(function() {
      that.tableDataSync()
    }, 200)
  }
  /**
   * 签名列表
   * @param pageNumber
   Object.assign(para, that.state.keywords)
   * @param pageSize
   * @constructor
   */
  tableDataSync = (pageNumber = 1) => {
    let that = this
    let para = {}
    para.pageNumber = pageNumber
    para.pageSize = 20
    that.setState({
      tableLoad: true
    })
    Object.assign(para, that.state.searchKey)
    apis.crawlerModelFieldMappingList(para).then(function(resp) {
      that.setState({
        tableLoad: false
      })
      var curr = {
        total: resp.data.data.total,
        pageNumber: pageNumber
      }
      that.setState({
        tableData: resp.data.data.data,
        paging: curr
      })
    })
  }


  render() {
    var that = this

    const columns = [
      {
        title: 'ID',
        dataIndex: 'id'
      },
      {
        title: '采集源',
        key: 'id',
        render: function(value, row, index) {
          return (
            <div>{row.engine.name}</div>
          )
        }
      },
      {
        title: '数据模型',
        key: 'id',
        render: function(value, row, index) {
          return (
            <div>{row.crawler_model.name}</div>
          )
        }
      },
      {
        title: '可用',
        key: 'id',
        render: function(value, row, index) {
          return (
            <ui.Button shape={'round'} type={'circle'}>{row.state ? '是' : '否'}</ui.Button>
          )
        }
      },
      {
        title: '创建时间',
        key: 'id',
        render: function(value, row, index) {
          return (
            <div>{dayjs(row.created_at).format("YYYY-MM-DD hh:mm:ss")}</div>
          )
        }
      },
      {
        title: '管理',
        key: 'id',
        width: 100,
        align: 'center',
        fixed: 'right',
        render: function(value, row, index) {
          return (
            <span>
                 <ui.Button onClick={() => {
                   var data = JSON.parse(JSON.stringify(row))
                   data.mapping = data.mapping ? JSON.parse(data.mapping) : { }
                   data = FormatUtils.FormDataJsonEncoder(data,["mapping"])
                   data.engine = data.engine.id
                   that.engineFieldsSync(data.engine)
                   data.crawler_model = data.crawler_model.id
                   that.modelFieldsSync(data.crawler_model)
                   that.setState({
                     editCurr: data,
                     editShow: true
                   })
                 }} type={'link'}>编辑</ui.Button>
            </span>
          )
        }
      }
    ]

    return (
      <div>
        <PageHeader title="模型字典管理" subTitle="管理数据模型字段转换器" extra={[
          <ui.Button key="1" type="primary" shape="round" onClick={() => {
            that.setState({
              editCurr: {},
              editShow: true,
              submitting: false
            })
          }}>新增模型转换规则</ui.Button>
        ]} />
        <ui.Card>
          <div style={{ marginTop: '22px' }}>
            <ui.Form onFinish={that.makeSearch} layout={'inline'}>
              <ui.Form.Item label={'模型字典名称'} name={'name__contains'}>
                <ui.Input placeholder={'模型字典名称'} />
              </ui.Form.Item>
              <ui.Form.Item>
                <ui.Button type={'primary'} htmlType={'submit'}>快速搜索</ui.Button>
              </ui.Form.Item>
            </ui.Form>
          </div>
        </ui.Card>

        <div style={{ marginTop: '22px' }}>
          <ui.Table
            rowKey="id"
            dataSource={this.state.tableData}
            columns={columns}
            loading={this.state.tableLoad}
            pagination={{
              total: this.state.paging.total,
              defaultPageSize: 20,
              defaultCurrent: this.state.paging.pageNumber,
              onChange: this.tableDataSync
            }} />
        </div>

        <ui.Modal open={that.state.editShow} title={'定时任务模型字典名称'} onCancel={() => {
          that.setState({
            editCurr: {},
            editShow: false,
            submitting: false
          })
        }} footer={null} destroyOnClose>
          <ui.Form onFinish={(params => {
            params = FormatUtils.FormDataJsonDecoder(params)
            params.mapping = JSON.stringify(params.mapping)
            that.setState({
              submitting: true
            })
            if (that.state.editCurr.id) {
              params.id = that.state.editCurr.id
            }
            apis.crawlerModelFieldMappingModifier(params).then(resp => {
              that.setState({
                submitting: false
              })
              if (resp.data.code === 200 && resp.data.data.code === 200) {
                ui.message.success('提交信息成功')
                that.tableDataSync(that.state.editCurr.id ? that.state.paging.pageNumber : 1)
                that.setState({
                  submitting: false,
                  editShow: false,
                  editCurr: {}
                })
              } else {
                ui.message.error(resp.data.data.msg ? resp.data.data.msg : resp.data.msg)
              }
            })
          })} labelCol={{ span: 24 }} labelAlign={'left'} initialValues={that.state.editCurr}>
            <ui.Form.Item label={'数据源'} name={'engine'} rules={[{ required: true, message: '数据源' }]}>
              <ui.Select showSearch={true} onSearch={kw => {
                that.sourceSync(kw)
              }} disabled={that.state.editCurr.id}  placeholder={'数据源'} style={{ width: '100%' }} onChange={v => {
                that.engineFieldsSync(v)
              }}>
                {that.state.engine.map(item => {
                  return (
                    <Option value={item.id} key={item.id}>{item.name}</Option>
                  )
                })}
              </ui.Select>
            </ui.Form.Item>
            <ui.Form.Item label={'数据模型'} name={'crawler_model'} rules={[{ required: true, message: '数据模型' }]}>
              <ui.Select disabled={that.state.editCurr.id} showSearch={true} onSearch={kw => {
                that.modelSync(kw)
              }} placeholder={'数据模型'} style={{ width: '100%' }} onChange={v => {
                var tmp = that.state.editCurr
                tmp.model = v
                that.setState({
                  editCurr: tmp
                })
                that.modelFieldsSync(v)
              }}>
                {that.state.model.map(item => {
                  return (
                    <Option value={item.id} key={item.id}>{item.name}</Option>
                  )
                })}
              </ui.Select>
            </ui.Form.Item>
            {that.state.modelFields.length > 0 &&
              <fieldset>
                <h4>模型字典</h4>
                {that.state.modelFields.map(item => {
                  return (
                    <ui.Form.Item key={item.id} label={item.label} name={`mapping__${item.field}`} rules={[{ required: true, message: item.name }]}>
                      <ui.Select placeholder={item.name} style={{ width: '100%' }}>
                        {that.state.engineFields.map(item => {
                          return (
                            <Option value={item.id} key={item.id}>{item.name}</Option>
                          )
                        })}
                      </ui.Select>
                    </ui.Form.Item>
                  )
                })}
              </fieldset>
            }
            <ui.Form.Item>
              <ui.Button htmlType={'submit'} loading={that.state.submitting} type={'primary'} block>立即提交</ui.Button>
            </ui.Form.Item>
          </ui.Form>
        </ui.Modal>
      </div>
    )
  }
}