/**
 * author: Alex Lai
 * Support Website: https://laialex.com
 * Email: alex@laialex.com
 * Create Time: 2024/10/8
 */
import React, { Component } from 'react'
import * as ui from 'antd'
import apis from '../../../../utils/apis'
import { BellOutlined } from '@ant-design/icons'
import { PageHeader } from '@ant-design/pro-layout'
import dayjs from 'dayjs'


const { TextArea } = ui.Input
const { Option } = ui.Select

export default class CrawlerObjects extends Component {
  constructor() {
    super()
    this.state = {
      tableData: [],
      paging: { total: 0, pageNumber: 1, pageSize: 20 },
      tableLoad: false,
      searchKey: {},
      editShow: false,
      editCurr: {},
      submitting: false
    }
  }

  componentDidMount() {
    this.tableDataSync(1)
  }


  /**
   * 发起搜索
   * @param params
   * @constructor
   */
  makeSearch = (params) => {
    this.setState({
      searchKey: params
    })
    var that = this
    setTimeout(function() {
      that.tableDataSync()
    }, 200)
  }
  /**
   * 签名列表
   * @param pageNumber
   Object.assign(para, that.state.keywords)
   * @param pageSize
   * @constructor
   */
  tableDataSync = (pageNumber = 1) => {
    let that = this
    let para = {}
    para.pageNumber = pageNumber
    para.pageSize = 20
    that.setState({
      tableLoad: true
    })
    Object.assign(para, that.state.searchKey)
    apis.crawlerObjectList(para).then(function(resp) {
      that.setState({
        tableLoad: false
      })
      var curr = {
        total: resp.data.data.total,
        pageNumber: pageNumber
      }
      that.setState({
        tableData: resp.data.data.data,
        paging: curr
      })
    })
  }


  render() {
    var that = this

    const columns = [
      {
        title: 'ID',
        dataIndex: 'id'
      },
      {
        title: '数据源',
        key: 'id',
        render: function(value, row, index) {
          return (
            <div>
              <ui.Button type={'dashed'} shape={'round'} style={{backgroundColor: '#666',border:"none", color:"#fff"}} size={'small'}>{row.engine.domain}</ui.Button>
            </div>
          )
        }
      },
      {
        title: '页面标题',
        key: 'id',
        render: function(value, row, index) {
          return (
            <div style={{fontSize:"16px"}}>{row.name}</div>
          )
        }
      },
      {
        title: '重试次数',
        key: 'id',
        render: function(value, row, index) {
          return (
            <ui.Button type={'dashed'} size={'small'}>{row.retry_count}</ui.Button>
          )
        }
      },
      {
        title: '入库时间',
        key: 'id',
        render: function(value, row, index) {
          return (
            <div>{dayjs(row.created_at).format("YYYY-MM-DD hh:mm:ss")}</div>
          )
        }
      },
      {
        title: '可用',
        key: 'id',
        render: function(value, row, index) {
          return (
            <ui.Button shape={'circle'} size={'small'} type={'primary'}>{row.state ? '是' : '否'}</ui.Button>
          )
        }
      },
      {
        title: '管理',
        key: 'id',
        width: '120px',
        fixed: 'right',
        render: function(value, row, index) {
          return (
            <span>
                 <ui.Button onClick={() => {
                   var data = JSON.parse(JSON.stringify(row))
                   that.setState({
                     editCurr: data,
                     editShow: true
                   })
                 }} type={'link'}>编辑</ui.Button>
              <ui.Button href={row.url} target={'_blank'} type={'link'}>立即访问</ui.Button>
              <ui.Button href={`/bigdata/crawler/record?url__id=${row.id}`} target={'_blank'} type={'link'}>历史记录</ui.Button>
            </span>
          )
        }
      }
    ]

    return (
      <div>
        <PageHeader title="采集对像管理" subTitle="采集对像集中化管理" extra={[
          <ui.Button key="1" type="primary" shape="round" onClick={() => {
            that.setState({
              editCurr: {},
              editShow: true,
              submitting: false
            })
          }}>提交采集对像</ui.Button>
        ]} />
        <ui.Card>
          <div style={{ marginTop: '22px' }}>
            <ui.Form onFinish={that.makeSearch} layout={'inline'}>
              <ui.Form.Item label={'采集对像名称'} name={'name__contains'}>
                <ui.Input placeholder={'采集对像名称'} />
              </ui.Form.Item>
              <ui.Form.Item>
                <ui.Button type={'primary'} htmlType={'submit'}>快速搜索</ui.Button>
              </ui.Form.Item>
            </ui.Form>
          </div>
        </ui.Card>

        <div style={{ marginTop: '22px' }}>
          <ui.Table
            rowKey="id"
            dataSource={this.state.tableData}
            columns={columns}
            loading={this.state.tableLoad}
            pagination={{
              total: this.state.paging.total,
              defaultPageSize: 20,
              defaultCurrent: this.state.paging.pageNumber,
              onChange: this.tableDataSync
            }} />
        </div>

        <ui.Modal open={that.state.editShow} title={'定时任务采集对像名称'} onCancel={() => {
          that.setState({
            editCurr: {},
            editShow: false,
            submitting: false
          })
        }} footer={null} destroyOnClose>
          <ui.Form onFinish={(params => {
            that.setState({
              submitting: true
            })
            if (that.state.editCurr.id) {
              params.id = that.state.editCurr.id
            }
            apis.crawlerObjectModify(params).then(resp => {
              that.setState({
                submitting: false
              })
              if (resp.data.code === 200 && resp.data.data.code === 200) {
                ui.message.success('提交信息成功')
                that.tableDataSync(that.state.editCurr.id ? that.state.paging.pageNumber : 1)
                that.setState({
                  submitting: false,
                  editShow: false,
                  editCurr: {}
                })
              } else {
                ui.message.error(resp.data.msg)
              }
            })
          })} labelCol={{ span: 24 }} labelAlign={'left'} initialValues={that.state.editCurr}>
            <ui.Form.Item label={'采集对像名称'} name={'name'}
                          rules={[{ required: true, message: '采集对像名称' }]}>
              <ui.Input placeholder={'采集对像名称'} />
            </ui.Form.Item>
            <ui.Form.Item label={'采集对像状态'} name={'state'}
                          rules={[{ required: true, message: '采集对像状态' }]}>
              <ui.Radio.Group>
                <ui.Radio value={true}>可用</ui.Radio>
                <ui.Radio value={false}>禁用</ui.Radio>
              </ui.Radio.Group>
            </ui.Form.Item>
            <ui.Form.Item label={'URL地址'} name={'url'}
                          rules={[{ required: true, message: 'URL地址' }]}>
              <ui.Input  disabled={that.state.editCurr.id} placeholder={'URL地址'} />
            </ui.Form.Item>
            <ui.Form.Item label={'备注'} name={'remark'}
                          rules={[{ required: false, message: '备注' }]}>
              <TextArea rows={6} placeholder={'备注'} />
            </ui.Form.Item>
            <ui.Form.Item>
              <ui.Button htmlType={'submit'} loading={that.state.submitting} type={'primary'} block>立即提交</ui.Button>
            </ui.Form.Item>
          </ui.Form>
        </ui.Modal>
      </div>
    )
  }
}